import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import comityLogo from "../../components/img/comityLogo.png";
import backButton from "../../images/icons/back.png"; 
import "./signup.scss";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { useDispatch } from 'react-redux';
import userAction from "../../redux/users/action"; 
import { db } from "../../firebase"; 
import { doc, setDoc, collection, query, where, getDocs } from "firebase/firestore"; 
import FormField from "../../components/FormField";
import LanguageSwitcher from "../../components/LanguageSwitcher.jsx";

//* PART FROM MOBILE *//

async function signUpUser(User) {
  let success = true;
  let error = '';
  let data = {};

  const auth = getAuth();  
  await createUserWithEmailAndPassword(auth, User.email, User.password)
    .then(async user => {
      const cashierEmail = await getAllOfCollectionWhereInArray(
        'Organizations',
        'cashierEmail',
        User.email,
      );
      const adminEmail = await getAllOfCollectionWhereInArray(
        'Organizations',
        'adminEmail',
        User.email,
      );
      const stewardEmail = await getAllOfCollectionWhereInArray(
        'Organizations',
        'stewardEmail',
        User.email,
      );
      if (cashierEmail.length > 0) {
        for (let index = 0; index < cashierEmail.length; index++) {
          let element = cashierEmail[index];
          element?.cashierUid.push(user.user.uid);
          let temp = [...element?.cashierEmail];
          temp = temp.filter(item => {
            return item !== User.email;
          });
          await saveData('Organizations', element?.id, {
            ...element,
            cashierEmail: temp,
          });
        }
      }
      if (stewardEmail.length > 0) {
        for (let index = 0; index < stewardEmail.length; index++) {
          let element = stewardEmail[index];
          if (element?.stewardUid) element?.stewardUid.push(user.user.uid);
          else element.stewardUid = [user.user.uid];
          let temp = [...element?.stewardEmail];
          temp = temp.filter(item => {
            return item !== User.email;
          });
          await saveData('Organizations', element?.id, {
            ...element,
            stewardEmail: temp,
          });
        }
      }
      if (adminEmail?.length > 0) {
        for (let index = 0; index < adminEmail.length; index++) {
          let element = adminEmail[index];
          element?.adminUid.push(user.user.uid);
          let temp = [...element.adminEmail];
          temp = temp.filter(item => {
            return item !== User.email;
          });
          await saveData('Organizations', element?.id, {
            ...element,
            adminEmail: temp,
          });
        }
      }

      await saveData('Store', user.user.uid, {
        uid: user.user.uid,
        email: User.email,
        username: User.username,
        type: 'admin',
        activeOrg: '',
        createAt: new Date().getTime().toString(),
        country: User.country,
        postalCode: User.postalCode,
      });
      data = {
        uid: user.user.uid,
        email: User.email,
        username: User.username,
        type: 'admin',
        activeOrg: '',
        createAt: new Date().getTime().toString(),
        country: User.country,
        postalCode: User.postalCode,
      };
    })
    .catch(function (err) {
      success = false;
      error = err;
    });
  return {error, success, data};
}

async function saveData(collectionName, docId, jsonObject) {
  let success = false;
  await setDoc(doc(db, collectionName, docId), jsonObject, { merge: true }) 
    .then(() => {
      success = true;
    })
    .catch(function (error) {
      console.error('Error writing document: ', error);
      success = false;
    });
  return success;
}

async function getAllOfCollectionWhereInArray(collectionName, key, value) {
  let data = [];
  const q = query(collection(db, collectionName), where(key, 'array-contains', value));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    if (doc.exists()) {
      data.push(doc.data());
    } else {
      console.log('No document found!');
    }
  });
  return data;
}

//* END PART FROM MOBILE *//

function SignUp() {
  const { t } = useTranslation('signup');
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const { i18n } = useTranslation();
  const { setUser } = userAction;
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [country, setCountry] = useState('');

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleRepeatPasswordVisibility = () => {
    setShowRepeatPassword(!showRepeatPassword);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = {};
    setErrors({});
    
    if (formData.password !== formData.repeatPassword) {
      errors.password = t("passwordMismatch");
      errors.repeatPassword = t("passwordMismatch");
    } else if (formData.password.length < 6) {
      errors.password = t("passwordLength");
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      errors.email = t("invalidEmail");
    }

    if(country === ""){
      errors.country = t("invalidCountry")
    }

    if (Object.keys(errors).length === 0) {
      try {

        const data = {
          email: formData.email.toLowerCase(),
          password: formData.password,
          username: formData.username,
          postalCode: formData.postalCode,
          lang: i18n.language,
          country: country
        };

        const resp = await signUpUser(data);

        if (resp.success) {
          dispatch(setUser(resp.data));
          navigate('/dashboard/overview');
        } else {
          if(resp.error.code === "auth/email-already-in-use")
            errors.email = t("emailInUse");
        }

      } catch (error) {
        console.error(error);
      }
    }

    setErrors({ ...errors });
  };

  return (
    <div className="signupPage">
      <div className="container">
        <div className="row ai-center">
          <div className="col-md-8">
            <div className="commityImg">
              <img className="img-fluid" src={comityLogo} alt={t("altText")} />
            </div>
          </div>
          <div className="col-md-4">
            <div className="signupForm">
              <div className="titleContainer">
                <button className="buttonBackContainer" onClick={() => navigate('/login')}>
                  <img src={backButton} alt="Back" />
                  <p>{t('return')}</p>
                </button>
                <h3>{t("welcome")}</h3>
              </div>
              <form onSubmit={handleSubmit}>
                <FormField 
                  name="username"  
                  value={formData.username}
                  onChange={handleChange}
                  error={errors?.username} 
                  label={t("username")}
                />
                <FormField 
                  name="email"  
                  value={formData.email}
                  onChange={handleChange}
                  error={errors?.email} 
                  label={t("email")}
                />
                <FormField 
                  name="postalCode"  
                  value={formData.postalCode}
                  onChange={handleChange}
                  error={errors?.postalCode} 
                  label={t("postalCode")}
                />
                <FormField 
                  name="country"  
                  value={country}
                  onChange={setCountry}
                  error={errors?.country} 
                  label={t("country")}
                  isCountry
                />
                <FormField 
                  name="password"  
                  value={formData.password}
                  onChange={handleChange}
                  error={errors?.password} 
                  label={t("password")}
                  isPassword={true}
                  showPassword={showPassword}
                  togglePassword={togglePasswordVisibility}
                />
                <FormField 
                  name="repeatPassword"  
                  value={formData.repeatPassword}
                  onChange={handleChange}
                  error={errors?.repeatPassword} 
                  label={t("repeatPassword")}
                  isPassword={true}
                  showPassword={showRepeatPassword}
                  togglePassword={toggleRepeatPasswordVisibility}
                />
                <button className="signupBtn" type="submit">
                  {t("signUp")}
                </button>
              </form>
              <div class="languageSwitcherContainer">
                <LanguageSwitcher />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ); 
}

export default SignUp;
