import React, { useEffect, useState } from "react";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  getAuth,
} from "firebase/auth";
import { getDoc, doc } from "firebase/firestore";
import { useTranslation } from "react-i18next";

import { useAlert } from "react-alert";
import comityLogo from "../../components/img/comityLogo.png";
import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector, useStore } from "react-redux";

import userAction from "../../redux/users/action";
import { Loader2 } from "../../helper/loader";
import { db } from "../../firebase";
import FormField from "../../components/FormField";
import LanguageSwitcher from "../../components/LanguageSwitcher.jsx";

export const Login = () => {
  const { t } = useTranslation("login");
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const { setUser } = userAction;
  const dispatch = useDispatch();
  const alert = useAlert();
  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);

    const auth = getAuth();
    await signInWithEmailAndPassword(auth, formData?.email, formData?.password)
      .then(async (userCredential) => {
        // Connecté
        const userId = userCredential.user.uid;
        const userRef = doc(db, "Store", userId);
        let loadedUser;

        await getDoc(userRef)
          .then((snapshot) => {
            const data = {
              id: snapshot.id,
              ...snapshot.data(),
            };
            console.log(data);
            loadedUser = data;
          })
          .catch((err) => {
            console.log("Fetch user failed: " + err);
          });

        dispatch(setUser(loadedUser));

        setLoader(false);
        navigate("/dashboard/overview");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorMessage);
        setLoader(false);
        alert.show(t("invalidEmailOrPassword"));
      });
    // navigate('/dashboard/overview')
  };

  return (
    <div className="loginPage">
      <div className="container">
        <div className="row ai-center">
          <div className="col-md-8">
            <div className="commityImg">
              <img className="img-fluid" src={comityLogo} alt={t("altText")} />
            </div>
          </div>
          <div className="col-md-4">
            <div className="loginForm">
              <h3>{t("welcome")}</h3>
              <div className="desc">{t("festivalSubscriberAccess")}</div>
              <form onSubmit={handleSubmit}>
                <FormField
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  label={t("email")}
                />
                <FormField
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  label={t("password")}
                  isPassword={true}
                  showPassword={showPassword}
                  togglePassword={togglePasswordVisibility}
                />
                <Link to={"/forget-password"} className="forget display-block">
                  {t("forgotPassword")}
                </Link>
                <button className="loginBtn" type="submit">
                  {t("login")}
                </button>
                <button
                  className="signupBtn "
                  onClick={() => navigate("/signup")}
                >
                  {t("noAccount")}
                </button>
              </form>
              <div class="languageSwitcherContainer">
                <LanguageSwitcher />
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader && <Loader2 loader={loader} />}
    </div>
  );
};
