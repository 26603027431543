import React from "react";
import Modal from "react-modal";
import { useTranslation } from 'react-i18next';

Modal.setAppElement('#root'); 

const ConfirmDeleteModal = ({ isOpen, onClose, onConfirm }) => {
  const { t } = useTranslation('deleteModal');

  return (
    <Modal 
      isOpen={isOpen} 
      onRequestClose={onClose} 
      contentLabel={t('confirmDeletion')}
      className="modalContent"  
    >
      <h3 className="modalHeader">{t('confirmDeletion')}</h3>
      <p>{t('deleteMessage')}</p>
      <div className="buttonsContainer">
        <button onClick={onClose} className="modalButton">{t('cancel')}</button>
        <button onClick={onConfirm} className="modalButton">{t('confirm')}</button>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteModal;
