import { CountryDropdown } from "react-country-region-selector";
import { useTranslation } from "react-i18next";

function FormField({ 
    name, 
    value, 
    onChange, 
    error, 
    label, 
    isCountry = false, 
    isPassword = false, 
    showPassword, 
    togglePassword 
  }) {

    const { t } = useTranslation('fieldForm'); 

    const renderInput = () => {
        if (isPassword) {
            return (
                <div className="input-group">
                    <input
                        name={name}
                        value={value}
                        onChange={onChange}
                        required
                        className="form-control"
                        type={showPassword ? "text" : "password"}
                    />
                    <button type="button" onClick={togglePassword}>
                        {showPassword ? t("hide") : t("show")}
                    </button>
                </div>
            );
        } 
        
        if (isCountry) {
            return (
                <CountryDropdown
                    value={value}
                    onChange={onChange}
                    className="form-control" 
                    defaultOptionLabel={t("selectCountry")}
                />
            );
        } 
        
        return (
            <input
                name={name}
                value={value}
                onChange={onChange}
                required
                className="form-control"
                type="text" 
            />
        );
    };
  
    return (
        <div className={`${error && 'error-group'}`}>
            <label htmlFor={name}>{label}</label>
            {renderInput()} 
            {error && <p className="errorMessage">{error}</p>}
        </div>
    );
}

export default FormField;