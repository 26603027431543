import React, { useRef } from "react";
import Modal from "react-modal";
import { QRCodeCanvas } from "qrcode.react";
import { useTranslation } from "react-i18next";

const QrCodeModal = ({ isOpen, onRequestClose, qrValue }) => {
  const { t } = useTranslation("qrCodeModal");
  const qrRef = useRef(null);

  Modal.setAppElement("#root");

  const handleDownloadQr = () => {
    const canvas = qrRef.current;
    if (!canvas) return;

    const qrSize = canvas.width;
    const padding = 50;
    const finalSize = qrSize + padding * 2;

    const logo = new Image();
    logo.src = require("../images/miniLogo.png");

    logo.onload = () => {
      const totalHeight = finalSize + logo.height + padding;

      const tempCanvas = document.createElement("canvas");
      tempCanvas.width = finalSize;
      tempCanvas.height = totalHeight;
      const tempCtx = tempCanvas.getContext("2d");

      tempCtx.fillStyle = "white";
      tempCtx.fillRect(0, 0, finalSize, totalHeight);

      const logoX = (finalSize - logo.width) / 2;
      tempCtx.drawImage(logo, logoX, padding, logo.width, logo.height);

      tempCtx.font = "16px Arial";
      tempCtx.textAlign = "center";
      tempCtx.fillStyle = "black";
      tempCtx.fillText(
        "Cashier Pass",
        finalSize / 2,
        logo.height + 2 * padding - 10
      );

      tempCtx.fillStyle = "white";
      tempCtx.fillRect(padding, logo.height + 2 * padding, qrSize, qrSize);

      tempCtx.drawImage(
        canvas,
        padding,
        logo.height + 2 * padding,
        qrSize,
        qrSize
      );

      const link = document.createElement("a");
      link.href = tempCanvas.toDataURL("image/png");
      link.download = "qrcode.png";
      link.click();
    };

    logo.onerror = () => {
      console.error("Erreur lors du chargement du logo.");
    };
  };

  const hasValidData = qrValue?.password && qrValue?.email;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={t("qrCode")}
      className="modalContent"
      style={{
        overlay: {
          backgroundColor: "rgba(9, 0, 43, 0.74)",
        },
      }}
    >
      <h2 className="modalHeader">{t("qrCode")}</h2>

      {hasValidData ? (
        <div className="qrCodeContainer">
          <QRCodeCanvas
            value={JSON.stringify(qrValue)}
            size={256}
            ref={qrRef}
          />
        </div>
      ) : (
        <p>{t("notEnoughInfo")}</p>
      )}

      <div className="buttonsContainer">
        {hasValidData && (
          <button onClick={handleDownloadQr} className="modalButton">
            {t("download")}
          </button>
        )}
        <button onClick={onRequestClose} className="modalButton">
          {t("close")}
        </button>
      </div>
    </Modal>
  );
};

export default QrCodeModal;
