import ENV from "../env";

const createUserCashierStore = async (details) => {
  const response = await fetch(
    `${ENV.firebaseApiUrl}/user/cashier/create`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(details),
    }
  );

  const resData = await response.json();

  if (!response.ok) {
    throw new Error("Create user cashier store failed: " + resData.message);
  }

  return resData;
};

export default createUserCashierStore;